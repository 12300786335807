.score {
    font-size: 1.4rem;
    margin: 0.5rem;
    color: #EEE;
}

/* Media Queries */
@media (max-width: 400px) {
    .score {
        font-size: 0.7rem;
    }
}