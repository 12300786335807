.App {
  max-width: 1280px;
  width: 100%;
  height: 70vh;
  margin: auto;
  border: 0.5rem solid #EEE;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./assets/img/background.png);
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

/* Media Queries */
@media (max-width: 1280px) {
  .App {
    width: 95%;
    border: 0.2rem solid #EEE;
  }
}

@media (max-width: 1024px) {
  .App {
    top: 53%;
    height: 50vh;
  }
}
