.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 25px 0px 0px 0px;
}

.mario-logo {
    width: 80px;
}

.ether-logo {
    width: 270px;
}

.title-container a {
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.title-container a:hover {
    color: #2856F6; /* Hover effect to change color */
}

/* Media Queries for mobile devices */
@media (max-width: 768px) {
    .title-container {
        flex-direction: column; /* Stack items vertically */
    }
    .mario-logo {
        width: 60px;
        height: auto;
    }
    .ether-logo {
        width: 180px;
        height: auto;
    }
    .title-container a {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .title-container {
        flex-direction: column;
        gap: 1px;
    }
    .mario-logo {
        width: 50px;
    }
    .ether-logo {
        width: 150px;
    }
    .title-container a {
        font-size: 1rem;
    }
}
