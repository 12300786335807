.leaderboard-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .leaderboard-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Poppins', sans-serif; /* Assuming you're using Poppins */
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    font-family: 'Poppins', sans-serif;
  }
  
  .leaderboard-table th {
    background-color: #3455ED;
    color: white;
    font-weight: bold;
  }
  
  .leaderboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .leaderboard-table tr:hover {
    background-color: #ddd;
  }
  
  .leaderboard-table td {
    font-size: 16px;
    color: #555;
  }
  
  .leaderboard-table td:first-child {
    font-weight: bold;
  }
  
  .leaderboard-table td:last-child {
    color: #3455ED;
    font-weight: bold;
  }
  
  @media screen and (max-width: 600px) {
    .leaderboard-container {
      padding: 10px;
    }
  
    .leaderboard-table th, .leaderboard-table td {
      font-size: 14px;
      padding: 8px;
    }
  }
  

  .leaderboard-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .user-info {
    margin-bottom: 20px;
    background-color: #e0ffe0;
    padding: 10px;
    border: 1px solid #4caf50;
    border-radius: 8px;
  }
  
  .user-info p {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
  }
  
  .retweet-button {
    background-color: #1da1f2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .retweet-button:hover {
    background-color: #0d8ddf;
  }
  