.whitepaper-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
  }
  
  .whitepaper-container h1 {
    text-align: center;
    color: #2856f6;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .whitepaper-section {
    margin-bottom: 30px;
  }
  
  .whitepaper-section h2 {
    color: #2856f6;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .whitepaper-section p {
    line-height: 1.6;
    font-size: 1rem;
    color: #333;
  }
  
  .whitepaper-section ul {
    margin-left: 20px;
    padding-left: 0;
  }
  
  .whitepaper-section ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .whitepaper-section strong {
    font-weight: bold;
    color: #2856f6;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .whitepaper-container {
      padding: 15px;
    }
  
    .whitepaper-section h2 {
      font-size: 1.5rem;
    }
  
    .whitepaper-section p,
    .whitepaper-section ul li {
      font-size: 0.9rem;
    }
  }
  