.footer-container {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 20px;
    color: #EEE;
    z-index: 100;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .social-link {
    color: #EEE;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: color 0.3s ease;
  }
  
  .social-link:hover {
    color: #1da1f2; /* Twitter blue for Twitter link */
  }
  
  .social-icon {
    font-size: 1.2rem;
  }
  
  .copyright {
    font-size: 0.8rem;
  }
  
  .copyright-link {
    color: #2856F6;
  }
  
  .copyright-link:hover {
    text-decoration: underline;
  }
  
  html, body {
    height: 100%;
    margin: 0;
    overflow-y: auto; /* Allow vertical scrolling */
  }