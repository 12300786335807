.brick {
    background-image: url("../../../assets/img/brick.png");
    background-repeat: repeat-x;
    background-size: contain;
    height: 130px;
    width: 1100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.brick-animate {
    animation-name: bricks;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes bricks {
    0% {
        left: 0px;
    }
    100% {
        left: -1000px
    }
}

/* Media Queries */
@media (max-width: 1280px) {
    .brick {
        height: 100px;
    }
}

@media (max-width: 1024px) {
    .brick {
        height: 70px;
    }
}